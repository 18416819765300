.img_part_big {
    position: relative;
    z-index: 1;
}

.img_part_big-zoom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ccc;
    padding: 8px 0;
    font-size: 40px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
}

.img_gallery_small a.mobx img {
    max-width: 50px !important;
}

@media (min-width: 992px) {
    .img_part_big {
        margin: 0 0 20px 0;
    }
}

.img_part {
    position: relative;
    z-index: 1;
    margin: 0 0 20px 0;
}

.img_part:after {
    clear: both;
    display: table;
    content: '';
}


.img_part .img_gallery {
    position: relative;
}

.img_part .img_gallery:before {
    display: block;
    padding-top: 100%;
    padding-bottom: 56px;
    content: '';
}

.img_part .img_gallery li {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0s 0.4s;
    /*display: none;*/
	
	/* for centering img vertically and horizontally */
	display: flex;
    justify-content: center;
    align-items: center;
	width: 100%;
    height: 100%;
}

.img_part .img_gallery li.selected {
    /*display: block;*/
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.img_part .img_gallery li > img {
    width: 100%;
    display: block;
}

/*
.img_part .img_gallery li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 56px;
}
*/

.img_part .img_gallery li picture {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.img_part .img_gallery li img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

@media (min-width: 1200px) {

    .img_part_big-zoom {
        display: none;
    }

    .img_part .img_gallery:before {
        padding-bottom: 0;
    }

    .img_part .img_gallery li a {
        padding-bottom: 0;
    }
}


.img_part .img_part_small_centered {
    width: auto;
}

.img_part .img_part_small_centered .img_gallery_small {
    max-width: none;
    float: none;
    /* margin-right: auto;
    margin-left: auto; */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
}

.img_part .img_gallery_small {
    max-width: 201px;
    overflow: hidden;
    float: left;
    margin-top: -13px;
}

.img_part .img_gallery_small li.selected {
    background: url("../img/horizontal_carousel_item.png") center 0 no-repeat;
}

.img_part .img_gallery_small li {
    width: 67px !important;
    padding: 8px 0 0 0;
    margin: 0;
    overflow: hidden;
    float: left;
    text-align: center;
}


.img_gallery {
    margin: 0;
    padding: 0;
    list-style: none;
}

.img_part .img_gallery_small li img {
    max-width: 63px;
    max-height: 60px;
    padding: 11px 4px 4px 4px;
}

.img_part .img_gallery_small li a {
    max-width: 60px;
    max-height: 60px;
}


.image:hover {
    cursor: pointer;
}


.img_part .img_part_small_centered .img_gallery_small-hovered {
    max-width: none;
    float: none;
    /* margin-right: auto;
    margin-left: auto; */
}

.img_part .img_gallery_small-hovered {
    max-width: 99999px;
    height: auto;
    overflow: visible;
    float: none;
    margin: 10px 0 0!important;
    padding: 0;
}

.img_gallery_small-hovered:after {
    display: table;
    clear: both;
    content: "";
}

.img_part .img_gallery_small-hovered li {
    position: relative;
    width: 70px !important;
    max-width: 70px;
    height: auto; /*70px;*/
    padding: 5px;
    margin: 5px;
    list-style: none;
    cursor: pointer;
    /* border: 1px solid #ccc; */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-grow: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
}

.img_part .img_gallery_small-hovered li:before {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    /*border: 1px solid #d8d8d8;*/
    visibility: visible;
    background: none;
    content: "";
    z-index: -1;
}

.img_part .img_gallery_small-hovered li.selected {
    cursor: default;
    background: none;
    /* box-shadow: inset 0 0 5px #676767; */
}

.img_part .img_gallery_small-hovered li.selected:before {
    /*border: 2px solid #c52411;*/
}

.img_part .img_gallery_small-hovered li img {
    display: inline-block;
    max-width: 9999px;
    max-height: 9999px;
    padding: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.img_part .img_gallery_small-hovered li a {
    max-width: 9999px;
    max-height: 9999px;
}


.img_part .img_gallery_small-carousel {
    display: flex;
    overflow: hidden;
}

.img_part .img_gallery_small-carousel.jcarousel-list {
    display: block;
}

.jcarousel-item-placeholder {
    display: none!important;
}

.img_part .img_gallery_small-carousel li {
    flex-shrink: 0;
}

.img_part .jcarousel-prev,
.img_part .jcarousel-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 26px;
    height: 46px;
    background: url("../img/product-slider-arrows.png") 0 0 no-repeat;
    cursor: pointer;
}

.img_part .jcarousel-prev:before,
.img_part .jcarousel-next:before {
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    content: '';
}

.img_part .jcarousel-next {
    background-position: right bottom;
    right: 10px;
}

.img_part .jcarousel-prev {
    background-position: left bottom;
    left: 10px;
}

.img_part .jcarousel-prev-disabled {
    background-position: left top;
}

.img_part .jcarousel-next-disabled {
    background-position: right top;
}

.img_part .jcarousel-prev-disabled,
.img_part .jcarousel-next-disabled {
    pointer-events: none;
    opacity: 0.8;
}

.img_part .jcarousel-container {
    width: 100%;
}

.img_part .jcarousel-clip {
    width: 100%;
    overflow: hidden;
}

.img_part .img_part_small_centered {
    width: auto;
}

.youtube-svg-icon:after {
	content: '';
    position: absolute;
    top: 25%; /*30%;*/
    left: 25%;/*35%;*/
    width: 50%; /*25px;*/
    height: 50%; /*20px;*/
    background: center center no-repeat;
    background-size: contain;
	background-image: url('/venmi/views/frontend/default/img/youtube-icon-logo.svg');
}

.youtube-svg-icon-big:after {
	content: '';
    position: absolute;
    top: 35%; /*30%;*/
    left: 35%;/*35%;*/
    width: 30%; /*25px;*/
    height: 30%; /*20px;*/
    background: center center no-repeat;
    background-size: contain;
	background-image: url('/venmi/views/frontend/default/img/youtube-icon-logo.svg');
	/*opacity: 0.7;*/
}

.vkvideo-svg-icon:after {
	content: '';
    position: absolute;
    top: 40%; /*30%;*/
    left: 35%;/*35%;*/
    width: 25%; /*25px;*/
    height: 25%; /*20px;*/
    background: center center no-repeat;
    background-size: contain;
	background-image: url('/venmi/views/frontend/default/img/vkvideo-icon-logo2.svg');
}

.vkvideo-svg-icon-big:after {
	content: '';
    position: absolute;
    top: 42%; /*30%;*/
    left: 42%;/*35%;*/
    width: 15%; /*25px;*/
    height: 15%; /*20px;*/
    background: center center no-repeat;
    background-size: contain;
	background-image: url('/venmi/views/frontend/default/img/vkvideo-icon-logo2.svg');
	/*opacity: 0.7;*/
}

@media (max-width: 1199px) {
    .youtube-svg-icon-big:after {
        top: 28.5%;
    }
}