
@media (max-width: 1900px) {

    .product-description-top-brand .popover {
        display: none!important;
    }
}


@media (max-width: 1480px) {

    .category-banner-side {
        display: none;
    }

    .wrap.wrap-main-menu .container {
        width: 100% !important;
    }
    /*Centered nav bar on tablet version*/
    #main-navbar .navbar .navbar-collapse {
        text-align: center;
    }

    #main-navbar .navbar .navbar-nav {
        /*display: inline-block;*/
        float: none;
        vertical-align: middle;
    }
    /*Centered nav bar on tablet version(END)*/
    div.topline .container {
        width: 100% !important;
    }

    #topmenu ul.horizontal-menu li.menu-item a {
        padding: 10px 10px;
    }
}

@media (max-width: 1350px) {

    .horizontal-navbar .menu-level0 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .horizontal-navbar .menu-icon {
        font-size: 15px;
    }
}


@media (max-width: 1299px) {

    #main-left-container {
        width: 20%;
    }

    #main-right-container:not(.col-xs-12) {
        width: 80%;
    }

    .plitka-item {
        width: 33.33%;
    }

    .plitka-item:nth-child(1n) {
        clear: none;
    }

    .plitka-item:nth-child(3n+1) {
        clear: both;
    }
}


@media (max-width: 1259px) {

    #topmenu li.menu-item a {
        font-size: 13px;
    }

    #topmenu li.menu-item a .menu-icon {
        display: none;
    }

    .horizontal-navbar .menu-icon {
        display: none;
    }

    .kits-extras-block-header-white,
    .kits-extras-goto-category {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 1199px) {

    .cat-counter {
        margin-right: 30px;
    }

    #sort {
        float: left;
        width: auto;
    }

    #sort li.disabled {
        display: none;
    }

    .payment-method {
        width: 33.33%;
    }

    #main-left-container {
        width: 25%;
    }

    #main-right-container:not(.col-xs-12) {
        width: 75%;
    }
}

@media (max-width: 1099px) {

}


@media (max-width: 1024px) {
    #top-banner-2 {
        background-size: 400px;
    }

    .wrap.wrap-main-menu .container {
        width: 100% !important;
    }

    /*Centered nav bar on tablet version*/
    #main-navbar .navbar .navbar-collapse {
        text-align: center;
    }

    #main-navbar .navbar .navbar-nav {
        /*display: inline-block;*/
        float: none;
        vertical-align: middle;
    }
    /*Centered nav bar on tablet version(END)*/

    div.topline .container {
        width: 100% !important;
    }

    #topmenu ul.horizontal-menu li.menu-item a {
        padding: 10px 10px;
    }
}

@media (min-width: 1200px) {


    /*.container {
        width: 1152px;
    }*/


    .b-top {
        bottom: 20px;
    }

    .category-banner-left {
        margin-right: 590px;
    }

    .category-banner-right {
        margin-left: 590px;
    }

    .search-box {
        width: 450px;
    }

    .left-main {
        min-width: 279px;
        max-width: 279px;
    }

    .horizontal-navbar .menu-level0 {
        padding-left: 15px;
        padding-right: 15px;
    }

    /*.popular-categories {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }*/

    .review-body:after {
        display: table;
        clear: both;
        content: "";
    }

    .review-body-text {
        float: left;
        width: 665px;
    }

    .review-verdict-text {
        float: left;
        width: 645px;
    }

    .popular-categories a {
        min-width: 16.6%; /*182px;*/
        max-width: 16.6%; /*182px;*/
    }

    .popular-categories2 {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .popular-categories2 a {
        min-width: 182px;
        max-width: 182px;
    }

    .popular-categories-name {
        padding-left: 4px;
        padding-right: 4px;
    }

    .plitka-item {
        /*width: 279px;*/
    }


    .plitka--owl .plitka-item {
        width: 328px;
        width: 328.5px;
    }
    .plitka--owl .owl-item .plitka-item {
        width: 100%;
    }

    .plitka--owl {
        /*max-width: 986px;*/
    }

    .viewedproducts-link--horz {
        width: 316px;
    }

    .owl-item .viewedproducts-link--horz {
       widht: auto;
    }
    /*
        .category-banners {
            display: none;
        }*/

    .material-full ~ ymaps {
        width: auto !important;
    }
/*
    .material-full > table {
        width: auto !important;
    }*/

    .rate-sort {
        width: 415px;
    }

    .review-body:after {
        display: table;
        clear: both;
        content: "";
    }

    .tab-pane {
        padding-bottom: 25px;
    }

    #after-buy-form .modal-dialog {
        width: 600;
        margin-left: auto;
        margin-right: auto;
    }

    #buy1click .modal-dialog {
        width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    .buy1click-phone {
        width: 100%;
    }

    #buy1click textarea {
        width: 100%;
    }

    .buy1click-enterphone {
        padding-left: 6px;
        padding-right: 6px;
    }

    .sub-price {
        margin-left: 10px;
        font-size: 20px;
    }

    .form-signin {
        max-width: 280px;
    }

    ul.files li a {
        display: block;
        margin-left: 30px;
    }

    .fileimage {
        float: left;
    }

    .product-links a.dotted2 {
        margin-top: 0;
    }
    /*
    .product-properties {
        overflow: auto;
    }*/

    .compare-property-value, .compare-product {
        width: 220px;
        min-width: 150px;
    }

    .img_part_big .img_gallery li a img {
        line-height: 1.2;
    }

    .kits-options > .popover, .kits-extras-items > .popover {
        display: none !important;
    }

    .radioname .product-variant-id {
        padding-left: 20px;
    }

    .compare-scroll {
        overflow-x: auto;
    }

    .review-body-text {
        float: left;
        width: 515px;
    }

    .review-verdict-text {
        float: left;
        width: 100%;
    }

    .main-slideshow img {
        max-width: 862px;
    }

    #main-right-container {
        padding-left: 0px; /*30px;*/
    }
}

@media (min-width: 1024px) {

    #main-container {
        margin-left: 0px;
        margin-right: 0px;
    }

    #main-left-container[style="display:none;"] + #main-right-container {
        padding-left: 0px;
    }
    #main-right-container {
        padding-right: 0;
    }

    #main-left-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    #main-left-container:not(.main-left-container--hidden) + #main-right-container {
        padding-left: 30px;
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    #main-row-complect-products {
        /* margin-top: -70px; */
    }
    .buy-side {
        /* margin-top: 17px;
        margin-left: -1px; */
    }
}

@media (max-width: 991px) {

    .respon-meter {
        z-index: 991;
    }

    .wrapall {
        padding-top: 100px;
    }

    .container {
        /*max-width: 0;*/
        width: auto;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .wrap-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #e5e5e5;
        background-color: #fff;
        z-index: 10;
    }

    .wrap-top-menu-replace {
        height: 0;
    }

    .scrolled .wrap-header {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    header {
        margin: 5px 0;
    }

    header > .row {
        display: flex;
        align-items: center;
    }

    header .col-xs-3 {
        width: auto;
        /*flex: auto 0 0;*/
        flex: auto 1 1;
    }

    .header-right {
        width: auto;
        flex: auto 1 1;
    }
/*
    .search-box .addcontainer {
        overflow-y: auto;
        max-height: 80vh;
    }
*/

    .search-box-replace-mobile {
        height: 70px;
    }

    .search-box ~ .search-box-replace-mobile {
        height: 0;
    }

    .search-box {
        padding: 15px;
        background: none;
        width: auto;
		padding-bottom: 0;
		top: 7px;
    }

    .search-box .input-group .form-control {
        height: 40px;
        padding: 8px 12px;
        padding-right: 40px;
        border-color: #ccc;
        border-radius: 0;
        box-shadow: none;
    }

    .searchclear {
        right: 120px;
        left: auto;
        top: 23px;
    }

    .search-box .btn {
        height: 40px;
        padding: 8px 12px;
        border-radius: 0;
        background: #f8f5ee!important;
        color: #333!important;
        min-width: 90px;
    }

    .search-box .addcontainer {
        width: auto;
        right: 15px;
        top: 100%;
        margin-top: -16px;
        left: 15px;
        border-radius: 0;
        margin-left: 0;
        z-index: 10;
    }

    .wrap-top-menu {
        display: none;
    }

    .menu-switcher {
        display: block;
    }

    .logo {
        width: 213px;
        height: 60px;
    }

    .mmenu-icon {
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
    }

    .main-slideshow, .main-slideshow-mobile {
        padding-top: 15px;
    }

    .fbbar__inner {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .fbbar__phone,
    .fbbar__feedback,
    .fbbar__compare,
    .fbbar__scart {
        padding-left: 15px;
        padding-right: 15px;
    }

    .fbbar__scart:before {
        display: none;
    }

    .fbbar__compare-action {
        padding-left: 0;
        padding-right: 0;
    }

    #make-callback {
        display: inline-block;
        vertical-align: top;
        margin-top: 0;
        background-color: #d5351d;
        padding: 6px 5px 8px;
        width: auto;
        /*min-width: 200px;*/
        min-width: 170px;
        height: 26px;
        border-radius: 0;
    }

    #contact-center .modal-footer {
        text-align: center;
    }

    #contact-center .modal-footer .btn {
        font-size: 12px;
        padding: 8px 20px 10px;
        background-color: #d5351d;
        width: auto;
        border: none;
        line-height: 1;
        min-width: 200px;
        height: 30px;
        border-radius: 0;
    }

    .main-slideshow-wrapper {
        padding-left: 60px;
        padding-right: 60px;
        max-width: none;
    }

    .main-slideshow, .main-slideshow-mobile {
        max-width: none;
        width: auto;
    }

    #content {
        float: left;
        width: 100%;
    }

    .block-class .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .logo_tablet {
        float: left;
        width: 150px;
        height: 38px;
        margin-top: 3px;
        background-size: contain;
    }


    .phone .mail {
        font-size: 12px;
    }

    .phone .number {
        margin-top: 4px;
    }

    .phone .ya-phone {
        display: block;
        margin-bottom: 3px;
    }

    .number {
        font-size: 16px;
    }

    .phone {
        min-width: 0;
        margin-left: 0;
        text-align: center;
    }


    #cart {
        position: relative;
        width: 36px;
        height: 36px;
    }

    .cart-counter {
        position: absolute;
        top: -4px;
        right: -6px;
        left: auto;
        border: none;
        padding: 3px;
        min-width: 19px;
        text-align: center;
    }

    .cart-mini-header {
        display: none;
    }

    .cart-mini-image {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -16px;
    }

    .cart-mini-price {
        display: none;
    }


    .topadress {
        display: none;
    }

    .work-time {
        display: none;
    }


    .wrap-main-menu {
        margin-top: 30px;
    }

    .left-main {
        min-width: 120px;
    }

    .left-main:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.4);
        content: "";
    }

    .left-main-header {
        position: relative;
        font-size: 16px;
        z-index: 1;
    }

    .left-main-body {
        position: relative;
        z-index: 1;
    }

    .b-top {
        bottom: 15px;
        right: 15px;
    }

    .popular-categories {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .popular-categories:after {
        display: table;
        clear: both;
        content: "";
    }

    .category-line {
        /*display: block;
        width: auto;*/
        flex-wrap: wrap;
    }

    .category-line:after {
        display: table;
        clear: both;
        content: "";
    }

    .group-products-filter {
        display: block;
        display: flex;
        font-size: 0;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-left: -15px;
        margin-right: -15px;
    }

    .one-category {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
    }

    .group-products-filter .one-category:first-child {
        padding-left: 15px;
    }

    .group-products-filter .one-category:last-child {
        padding-right: 15px;
    }

    .group-products-filter-element {
        padding: 5px 10px !important;
        margin-bottom: 10px;
    }

    .group-products-filter--products {
        white-space: normal;
        flex-wrap: wrap;
        overflow: visible;
    }

    .group-products-filter--products {
        margin-left: -5px;
        margin-right: -5px;
    }

    .group-products-filter--products .group-products-filter-element {
        margin-bottom: 0;
    }

    .group-products-filter--products .one-category {
        padding-bottom: 15px;
    }

    .group-products-filter--products .one-category:first-child {
        padding-left: 5px;
    }

    .group-products-filter--products .one-category:last-child {
        padding-right: 5px;
    }

    .found-categories:after {
        display: table;
        clear: both;
        content: "";
    }

/*    .found-categories-name {
        min-height: 45px;
    }*/

    .found-categories-image {
        height: 80px;
    }

    .popular-categories a {
        clear: none;
        display: block;
        float: left;
        width: 33%; /*16.6%;*/
        /*144px;*/
        min-width: 0;
        max-width: none;
    }

    .popular-categories2 a {
        clear: none;
        display: block;
        float: left;
        width: 144px;
        min-width: 0;
        max-width: none;
    }

    .popular-categories-image {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .popular-categories-name {
        min-height: 32px;
        padding-left: 2px;
        padding-right: 2px;
    }



    .products-bottom {
        display: flex;
        flex-direction: column;
    }

    .products-bottom .found-categories {
        order: 1;
    }

    .products-bottom .decription-category-bottom {
        order: 2;
    }



    .legend--smaller-mobile {
        font-size: 24px;
    }


    .rtgbs {
        margin-left: -10px;
        margin-right: -10px;
    }

    .rtgbs__inner {
        margin: -8px 0;
    }

    .rtgbs__container {
        max-width: 800px;
        margin: 0 auto;
    }

    .rtgb {
        width: 50%;
        padding: 8px 10px;
    }

    .rtgb__inner {
        min-height: 70px;
    }



    .stgbs {
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 15px;
    }

    .stgbs__inner {
        margin: -5px 0;
    }

    .stgb {
        width: 50%;
        padding: 0 10px;
        font-size: 15px;
    }

    .stgb:before {
        display: none;
    }

    .stgb:after {
        display: none;
    }

    .stgb__inner {
        border-bottom: 1px solid #ccc;
        max-width: 295px;
        margin: 0 auto;
        padding: 5px 0;
        min-height: 50px;
    }

    .stgb__icon {
        min-width: 45px;
    }



    .tooltip-data-title:hover:after {
        margin-bottom: 10px;
    }


    .products-content .plitka {
        display: flex;
        flex-wrap: wrap;
    }

    .products-content .product-inner {
        height: 100%;
    }

    .plitka-item {
        width: 50%;
    }

    .plitka-item:nth-child(1n) {
        clear: none;
    }

    .plitka-item:nth-child(2n+1) {
        clear: both;
    }

    /*.articles ul li {
        width: 33.33%;
    }

    .articles ul li:first-child + li + li + li {
        display: none;
    }*/


    .company-inner {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .review-body-text:first-child {
        margin-top: 0;
    }

    .review-body {
        width: auto;
    }

    .rewiews-shop-top-col {
        width: auto;
    }

    .company .col-xs-6 {
        padding-left: 6px !important;
    }

    /*.decription-category-top--products {
        display: none;
    }*/

    .group-products-wrapper {
        margin-bottom: 25px;
    }

    #view {
        display: none;
    }

    .limit-catalog-row, #limit-catalog {
        display: none;
    }

    .products-content {
        margin-top: 35px;
    }

    #main-left-container {
        width: 244px;
    }

    #main-right-container {
        width: auto;
        float: none;
        margin-left: 244px;
    }

    #main-left-container[style="display:none;"] + #main-right-container {
        margin-left: 0;
        clear: both
    }

    #sort {
        float: right;
    }

    .cat-counter {
        width: auto;
        min-width: 150px;
        margin-right: 15px;
    }

    .plitka-item {
        margin-bottom: 12px;
    }

    .owl-item .plitka-item {
        margin-bottom: 0;
    }

    #products-update-block .plitka-separator {
        display: none;
    }

    .product-col-right {
        width: 50%;
    }

    .product-col-left {
        width: 50%;
    }

    .product-col-center {
        width: 100%;
        margin-top: 50px;
    }

    .product-col-main {
        width: 100%;
    }


    .product-col-right #buy {
        clear: none;
    }

    /*
    .product-properties {
        max-height: 236px;
        height: auto;
    }*/

    .img_part {
        width: auto !important;
        float: none !important;
    }

    .nav-tabs > li > a {
        padding: 9px 17px 8px;
        font-size: 16px;
        font-weight: 500;
    }

    .tabpanel-col {
        width: 100%;
    }

    .tabpanel-col img {
        display: block;
    }

    .tabpanel-col + .tabpanel-col {
        display: none;
    }

    .review-body-text {
        float: left;
    }

    .carttable {
        font-size: 13px;
    }

    .carttable-product {
        width: auto;
    }

    .ordertable, .complecttable {
        font-size: 13px;
    }

    .ordertable-product-name {
        font-size: 13px;
    }


    .owl-item .viewedproducts-link--horz {
        width: auto !important;
        float: none !important;
    }
    /*
    .owl-item  {
        width: auto!important;
    }*/

    .owl-item .viewedproducts-link--horz:after {
        display: table;
        clear: both;
        content: "";
    }

    .cart-header {
        margin-bottom: 15px;
    }

    .nav-tabs > li > a {
        padding: 9px 17px 8px;
        font-size: 16px;
        font-weight: 500;
    }


    .selected-payment {
        display: block;
    }

    .selected-payment-legend span {
        width: auto;
        float: none;
    }

    .selected-payment-description {
        display: block;
    }



    //.group-products-wrapper {
    //    min-height: 156px;
    //}
    //
    //.collapseSubcategories {
    //    min-height: 44px;
    //    margin-bottom: 20px;
    //    overflow: hidden;
    //    display: block;
    //}
    //
    //.above-collapse .group-products-filter--products {
    //    height: 88px;
    //    overflow: hidden;
    //}
    //
    //.group-products-filter--empty {
    //    display: none!important;
    //}
    //
    //.collapseSubcategories:not(.in) {
    //    height: 0;
    //}
    //
    //.group-products-filter--products {
    //    margin-bottom: -20px;
    //}

    .payment-method {
        width: 50%;
    }


    .plitka-item .plitka-status {
        display: none;
    }

    .plitka-item .cena-name {
        display: none;
    }

    .plitka-item-banner .product-inner-two p span {
        font-size: 18px!important;
    }
}

@media (max-width: 321px) {
    .logo_tablet {
        width: 120px;
    }
}

@media (max-width: 920px) {


    #search {
        margin-left: 6%;
    }
/*
    .search-box {
        width: 250px;
    }

    .search-box .addcontainer {
        width: 510px;
        margin-left: -79px;
    }*/

    header {
        position: relative;
        z-index: 5;
    }

    #brands ul {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }

    .company-col {
        width: 100%;
    }

    .company-menu {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
        max-width: 420px;
    }

    .company-menu-header {
        margin-left: auto;
        margin-right: auto;
    }

    .company .vertical-menu {
        -moz-column-count: 2;
        -moz-column-gap: 10px;
        -webkit-column-count: 2;
        -webkit-column-gap: 10px;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 10px;
        -moz-column-gap: 10px;
        column-gap: 10px;
    }

	/*
    .payment-options {
        margin-top: 20;
    }
	*/

    .payment-options-icons {
        width: 220px;
        margin-left: auto;
        margin-right: auto;
    }

    .company-menu img[style="float:left"] {
        float: none !important;
    }

    .buy1click-phone-block {
        text-align: left;
        line-height: 1.42857143;
    }

    .buy1click-phone-block,
    .buy1click-enterphone {
        float: none;
        width: auto;
        max-width: 400px;
    }

    .buy1click-help-block {
        margin-top: 5px;
        max-width: 400px;
    }

    .nav-tabs > li > a {
        padding: 8px 12px 7px;
        font-size: 15px;
    }

}


@media (max-width: 839px) {

    #view {
        display: none;
    }

    .tab-pane {
        padding-bottom: 0;
    }
}

@media (max-width: 799px) {

    #search {
        margin-left: 10px;
    }

    .popular-categories a {
        width: 33%; /*16.6%;*/
        /*138px;*/
    }

    .popular-categories2 a {
        width: 138px;
    }

    #kontakti .tabpanel-col img {
        width: 100%;
        height: 100%;
    }

}

@media (max-width: 991px) {

    .top-banner-first,
    .top-banner-second {
        background-size: contain !important;
    }

}


@media (max-width: 767px) {

    #top-banner {
        flex-direction: column;
    }

    #top-banner-2 {
        background: #b82b1a;
        text-align: center;
    }

    .top-banner-2-second {
        margin-top: 8px;
    }

    .top-banner-2-second span {
        padding: 0px 20px;
        margin-bottom: 5px;
    }
    .top-banner-2-third p {
        padding: 6px 10px 0px 10px;
    }

    #top-banner {
        margin: 5px 0px 10px 0px;
    }

    .top-banner-first,
    .top-banner-second {
        background-size: contain !important;
        min-height: 45px;
    }

    .top-banner-first {
        background: url("../img/top-banner/top-banner-first.png") no-repeat center bottom;
        margin-bottom: 5px;
    }
    .top-banner-second {
        background: url("../img/top-banner/top-banner-second.png") no-repeat top center;
    }

    .respon-meter {
        z-index: 767;
    }

    .product-links a.dotted2 {
        display: none;
    }

    .mobile-block {
        display: block;
    }

    .left-main-wrapper {
        display: none;
    }


    .found-categories-item {
        width: 33.33%;
    }


    .found-categories__list--shorten .found-categories-item--4 {
        display: none;
    }


    #viewedproducts {
        border-top: none;
    }

    .wrap-content + #viewedproducts {
        padding-top: 0;
    }

/*
    #search {
        position: absolute;
        left: 50%;
        top: 0;
        width: 36px;
        height: 36px;
        margin-left: -71px;
        background-color: #f8f5ee;
        border-radius: 2px;
        cursor: pointer;
    }

    #search:before {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 24px;
        height: 24px;
        margin-left: -12px;
        margin-top: -12px;
        background: url("../img/icon-search.svg") center center no-repeat;
        background-size: contain;
        opacity: 0.54;
        content: "";
    }

    .search-box {
        position: absolute;
        left: 50%;
        top: 0;
        width: 320px;
        margin-left: -160px;
        border-radius: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0s 0.3s;
    }

    .search-box:before {
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 30px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        content: "";
    }

    .search-box:after {
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 30px;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        content: "";
    }

    #search.active .search-box {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    #search-ajax-loader {
        right: 132px;
    }

    .searchclear {
        right: 120px;
    }

    .search-box-hide-group-btn {
        display: table-cell;
    }
*/
    .header-right {
        position: static;
        float: right;
        margin-left: -20px;
        padding-left: 0;
    }


    .main-slideshow .swiper-slide {
        min-height: 0;
    }


    .module-ProductsController #main-left-container {
        display: none;
    }

    .menu-switcher.materials-menu-switcher {
        display: block;
        float: right;
        position: absolute;
        top: 0;
        right: 0;
    }

    .rtgb {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }

    .materials-menu {
        position: absolute;
        margin-top: -8px;
        top: 100%;
        right: 0;
        z-index: 1;
        min-width: 190px;
        padding: 10px 0;
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
        /*-webkit-filter: drop-shadow(0 1px 3px rgba(0,0,0,0.1));
        filter: drop-shadow(0 1px 3px rgba(0,0,0,0.1));*/
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.3s, visibility 0s 0.3s;
        transition: all 0.3s, visibility 0s 0.3s;
    }

    .materials-menu + #categories {
        display: none;
    }

    .csstransforms .materials-menu {
        top: 45px;
    }

    .materials-menu.active {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }

    .materials-menu ol li a {
        padding: 8px 20px;
    }

    #main-container .materials-menu {
        display: none;
    }

    #h1-sort-header-block .materials-menu {
        display: block;
    }

    #h1-sort-header-block {
        position: relative;
    }

    .module-ProductsController #h1-sort-header-block h1 {
        /*padding-right: 105px;*/
        padding-bottom: 0;
    }

    .materials-menu-header {
        display: none;
    }


    .limit-catalog-row {
        display: none;
    }

    .cat-counter {
        width: auto;
        float: none;
        width: auto;
        margin: 0;
    }

    .product-properties2 {
        width: 66%;
    }


    .sort-wrapper {
        display: flex;
        margin-top: 15px;
    }

    .cat-counter {

    }

    #sort {
        float: none;
        display: flex!important;
        flex: auto 1 1;
    }

    #sort li {
        flex: auto 1 1;
    }

    #sort li a {
        font-size: 12px;
        padding: 5px 5px;
        min-height: 30px;
        border-radius: 0!important;
    }

    #sort li:nth-child(5) {
        display: none;
    }

    button.btn-filter {
        display: flex;
        flex: auto 1 1;
        margin-left: 20px;
    }


    .module-ProductsController #main-left-container {
        display: none;
    }

    .found-subcategories {
        display: none;
    }

    #products-update-block + .found-subcategories {
        display: block;
    }


    .tabs-product .tab-content img {
        height: auto;
        max-width: 100%;
    }


    .kits-extras-goto-category-part2 {
        display: inline-block;
    }

    .kits-extras-goto-category-part2::first-letter {
        text-transform: uppercase;
    }

    #share-product {
        padding-top: 40px;
    }

    .ya-share2__list.ya-share2__list_direction_horizontal {
        display: flex;
        justify-content: space-between;
    }

      /*#filter {
          position: absolute;
          top: 36px;
          right: 0;
          width: 232px;
          border: none;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
          box-shadow: 0 0 5px rgba(0,0,0,0.2);
          visibility: hidden;
          opacity: 0;
          -webkit-transition: all 0.3s, visibility 0s 0.3s;
          transition: all 0.3s, visibility 0s 0.3s;
          z-index: 11;
      }

      #filter.active
      {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
          -webkit-transition-delay: 0s;
          transition-delay: 0s;
      }*/

    #filter {
        /*position: absolute;
        top: 36px;
        right: 0;
        width: 232px;
        box-shadow:  0 0 5px rgba(0, 0, 0, 0.2);*/
        margin: 0;
        padding: 30px 0;
        background-color: transparent;
        border: none;
        visibility: visible;
        opacity: 1;
        z-index: 11;
    }

    #filter.active {
        visibility: visible;
        opacity: 1;
    }

    #filter .form-group {
        border-bottom: 1px solid #ccc;
    }

    #filter .panel-body {
        border-bottom: 1px solid #ccc;
    }

    #filter label.labelcollapse {
        font-size: 16px;
        font-weight: 400;
        padding: 6px 12px;
    }

    #filter i.fa-caret-right, #filter i.fa-caret-down {
        margin-left: 15px;
        color: #333;
        width: auto;
    }

    #filter .form-group--collapsed .fa-caret-down {
        transform: rotate(-90deg);
    }

    .qtip-wiki {
        max-width: 100%;
        left: 0!important;
    }

    #filter .filterbox {
        background-color: #fff;
    }

    #filter .filterbox.collapse {
        height: 0!important;
    }

    #filter .filterbox.collapse.in {
        height: auto!important;
    }

    #filter .filterbox__inner {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #filter .logicbox {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0!important;
        border-bottom: 1px solid #ccc;
    }

    #filter .logicbox .checkbox, #filter .logicbox .radio {
        margin-bottom: 0;
    }

    #filter .logicbox {
        padding-left: 14px;
    }

    #filter .form-group--range .filterbox {
        background-color: transparent;
    }

    #filter .form-group--range .filterbox__inner {
        padding: 5px 15px 0;
    }

    #filter .form-group {
        position: relative;
    }

    .form-group-over {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }

    /*#filter .irs {
        width: 245px;
    }*/



    #filter .range .input-group-addon {
        //float: left;
        display: block;
        padding: 0;
        width: 105px;
        color: #ccc;
        font-size: 16px;
        text-align: left;
        margin-bottom: 5px;
    }

    #filter .range .form-control {
        //float: left;
        width: 105px;
        border-radius: 0 !important;
        padding: 3px 5px;
        border: 1px solid #ccc;
        box-shadow: none;
        font-size: 14px;
        text-align: right;
    }

    #filter .filterbox.collapse div.checkbox:not(.checked), #filter .filterbox.collapse div.radio:not(.checked) {
        position: absolute;
        left: -9999px;
        z-index: -1;
        opacity: 0;
    }

    #filter .filterbox.collapse span.checkbox {
        position: absolute;
        left: -9999px;
        z-index: -1;
        opacity: 0;
    }

    .modal--filter-group .modal-header {
        font-size: 20px;
    }

    .modal-content .filterbox__inner {
        padding-right: 15px;
    }

    .modal-content .filterbox__inner .items-collapse {
        padding-left: 15px;
    }

    .modal-content .filterbox__inner .checkbox, .modal-content .filterbox__inner .radio {
        margin: 0 0 10px;
        min-height: 10px;
    }

    .modal-content .filterbox__inner .checkbox label, .modal-content .filterbox__inner .radio label {
        font-size: 16px;
    }

    .modal-content .filterbox__inner label span.checkbox, .modal-content .filterbox__inner label span.radio {
        float: right;
        margin: 2px 0 0;
    }

    .modal-content .filterbox__inner .checkbox .tag-name, .modal-content .filterbox__inner .radio .tag-name {
        margin-left: 0;
        margin-right: 22px;
    }



    .modal-content .filterbox__inner .items-collapse {
        padding-top: 20px;
        padding-bottom: 20px;
        max-height: none;
        overflow-y: visible;
    }

    #filter .checkbox, #filter .radio {
        margin: 0px 0 4px;
        min-height: 10px;
    }

/*    .navbar-offcanvas2 > .navbar-nav {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: center;
    }*/

    .filter-counter {
        visibility: visible;
        opacity: 1;
        left: auto;
        right: 100%;
        margin-right: -6px;
        width: 70px;
        width: calc(100vw - 250px);
        height: auto;
        font-size: 12px;
        white-space: normal;
    }

    .filter-counter:before {
        transform: scaleX(-1);
        left: 100%;
    }

    .filter-counter a {
        margin-right: 50px;
    }

    #filter .panel-footer {
        padding: 15px;
    }

    #filter .btn {
        padding: 6px 10px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        border: 1px solid #ccc!important;
        background: #fff!important;
        color: #333!important;
        margin-bottom: 20px;
    }

    #filter .btn:last-child {
        margin-bottom: 0;
    }

    #filter .btn-default {
        background: #e5e5e5!important;
    }

    .loader {
        margin: 0;
    }

    .loading.fixed .loader {
        left: 15px;
        margin-left: 0;
    }


    /*.articles ul li {
        width: 50%;
    }

    .articles ul li:first-child + li + li {
        display: none;
    }*/

    #main-left-container, #main-right-container {
        float: none;
        width: 100%;
        margin-left: 0;
    }

    #main-right-container:not(.col-xs-12) {
        width: 100%;
    }

    #main-left-container {
        display: none;
    }

    .rewiews-shop-top-col {
        width: 100%;
    }

    .review-body-text,
    .review-title {
        float: none;
        display: block;
        margin-top: 10px;
    }

    #categories {
        display: none;
    }

    .review-body-text {
        width: auto;
    }

    .review-verdict-text {
        float: none;
        width: auto;
    }

    .found-categories {
        display: none;
    }

    .found-categories--sub {
        display: block;
    }

    .product-links .buy-compare {
        float: left;
        margin-top: 5px;
        clear: both;
    }

    .nav-tabs > li > a {
        padding: 8px 12px 7px;
        font-size: 15px;
    }

    .wrapall {
        padding-top: 53px;
    }

    .header-right {
        justify-content: flex-end;
    }

    .radioname {
        max-width: 400px;
        width: auto;
    }

    .mmenu-icon, .phone-icon {
        width: 36px;
        margin-top: 2px;
        height: 36px;
    }

    .mmenu-icon, .whatsapp-icon {
        width: 36px;
        margin-top: 2px;
        height: 36px;
    }

    .phone-icon {
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
    }

    .whatsapp-icon {
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
    }

    .phone {
        position: absolute;
        top: 0;
        right: 93px;
        z-index: 11;
        padding: 0 10px;
        background-color: #fff;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0s 0.3s;
    }

    .phone:before {
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 30px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        content: "";
    }

    .phone.active {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    .phone {
        top: 100%;
        margin-top: 6px;
        padding: 7px 10px 12px;
        left: -10px;
        right: -10px;
        margin-left: 0;
        text-align: center;
        border-bottom: 1px solid #eee;
    }

    .phone .ya-phone {
        display: block;
        margin-bottom: 4px;
    }

    #footer {
        display: none;
    }

    #breadcrumbs {
        padding-bottom: 12px;
    }




    #sortview {
        margin-top: 15px;
    }




    .delivery-subtotal {
        padding: 0;
        background: none;
    }

    .cart-block-action {
        border-top: 1px solid #ccc!important;
        padding-top: 10px;
    }

    .cart-block-action .row {
        display: flex;
        flex-direction: column;
        margin-top: -12px;
    }

    .cart-block-action .row > .hint {
        order: 100;
    }

    .cart-block-action .row > div {
        margin-top: 12px;
    }

    .cart-block-action .btn {
        padding-top: 13px;
        padding-bottom: 13px;
    }



    .panel--alt .panel-heading {
        padding-left: 10px;
        padding-right: 10px;
    }

    .panel--alt .panel-body {
        padding: 20px 0;
    }

    .panel--alt .panel-body--w-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
}


@media (max-width: 719px) {

    .my-review label {
        line-height: 1.2;
    }

    .img_part .img_gallery_small-hovered li {
        width: 25% !important;
        max-width: 25%;
    }

    .nav-tabs > li > a {
        padding: 7px 9px 6px;
        font-size: 13px;
        font-weight: 500;
    }

    #buy1click .modal-dialog {
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
    }

    .carttable-product-name {
        padding-left: 0;
    }

    .carttable-image {
        display: none;
    }

    .ordertable-image {
        display: none;
    }

    .ordertable-product-name {
        padding-left: 0;
    }


    .kits-items-all {
        font-size: 16px;
        padding-top: 8px;
    }

    .kits-items-summ .buy-price-copy {
        font-size: 22px;
    }

    .kits-items-summ {
        font-size: 16px !important;
        padding-top: 2px;
    }

    .kits-items-buy .btn {
        padding: 5px 10px;
    }

    .radioprice {
        float: none;
        text-align: left;
        margin: 0;
        padding: 0 0 5px 120px;
    }

    .radioname {
        max-width: 9999px;
        float: none;
        min-height: 0;
    }

    .radioname .product-variant-id {
        float: right;
    }

}

@media (max-width: 373px) {
    .doplistitem-center {
    }
}

@media (max-width: 639px) {

    #after-buy-form .modal-dialog {
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
    }

    .modal-footer-col {
        width: 100%;
    }

    .modal-footer-col + .modal-footer-col {
        margin-top: 15px;
    }

    .material-full img {
        display: block;
        float: none !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        padding: 0;
    }

    .kits-summ {
        padding: 5px 10px 3px;
    }

    .kits-items-all {
        padding-left: 0;
        padding-top: 0;
        font-weight: 400;
        font-size: 12px;
    }

    .kits-items-buy {
        position: absolute;
        top: 10px;
        right: 15px;
    }


    .kits-items-buy .btn {
        padding: 3px 5px;
        font-size: 16px;
    }

    .kits-items-summ {
        float: left;
        clear: both;
        padding-top: 0;
        font-size: 12px !important;
        margin: 0;
    }

    .kits-items-summ .buy-price-copy {
        font-size: 16px;
    }

    .radio label, .checkbox label {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .var-radio {
        padding-left: 9px;
    }

    .varimage {
        display: block;
        margin-left: 2px;
    }

    .radioname {
        display: block;
        margin-left: 100px;
        min-height: 0;
    }

    .radioname span {
        display: block;
    }

    .radioprice {
        padding-left: 100px;
    }

    .radioname .product-variant-id {
        margin-top: 8px;
    }

}


@media (max-width: 599px) {

/*    .articles ul li {
        width: 100%;
    }*/

    .rate-header > .write-review {
        float: none;
        margin-top: 20px;
    }

    .product-list-badges {
        display: block;
        clear: both;
        float: left;
    }

    .kodtovara {
        margin-bottom: 5px;
    }

    .tabpanel-col img {
        float: none !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
}

@media (min-width: 540px) {

	
    .img_part .img_gallery_small-carousel {
        display: flex;
        flex-wrap: wrap;
        /*justify-content: center;*/
    }

	/*
    .img_part .jcarousel-prev,
    .img_part .jcarousel-next {
        display: none!important;
    }

	.img_part .jcarousel-clip {
        overflow: visible;
        width: auto;
    }
*/
	
    .img_part .img_gallery_small-carousel.jcarousel-list {
        display: flex;
		/*
        width: auto!important;
        left: auto!important;
		*/
    }
	
	/*
	.img_part .img_gallery_small-carousel li {
        width: calc((100vw)/4 - 10px)!important;
    }
	*/

    .img_part_small {
        margin-left: -15px;
        margin-right: -15px;
    }
	
	/*.img_part_small .jcarousel-clip {
		margin-left: 45px;
		width: 80%;
	}*/

    .sort-type-icon {
        display: none;
    }

    #sort .sort-position i {
        display: none;
    }

}

@media (max-width: 539px) {

/*    header .col-xs-3 {
        flex: auto 0 0;
    }*/
	
	.found-categories-name {
		font-size: 13px;
	}

    #cart {
        float: none;
        display: inline-block;
        vertical-align: top;
    }


    .found-categories-item {
        width: 50%;
    }


    .found-categories__list--shorten .found-categories-item--3 {
        display: none;
    }
/*
    #search {
        position: static;
        display: inline-block;
        vertical-align: top;
        line-height: 42px;
        text-align: center;
    }

    #search:before {
        position: static;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
    }

    .search-box {
        top: 100%;
        left: 0;
        right: 0;
        width: auto;
        margin-left: 0;
        margin-top: 10px;
        line-height: 1.42857143;
        text-align: left;
        z-index: 1;
    }

    .search-box:before {
        background: #fff;
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
        z-index: -1;
    }

    .search-box:before,
    .search-box:after {
        display: none;
    }

    .search-box .addcontainer {
        width: auto;
        margin-left: 0;
        right: 0;
        left: 0;
        top: 100%;
        border-radius: 0;
    }*/

    #cart {
        margin-left: 20px;
    }

    .dropdown-menu > li > a {
        white-space: normal;
    }

    .navbar-default {
        background-color: transparent;
        border-color: transparent;
    }

    .navbar {
        min-height: 0px;
        margin-bottom: 0px;
    }

    #brands ul {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }

    .main-slideshow-wrapper {
        padding: 0;
    }

    .main-slideshow-wrapper .swiper-button-next,
    .main-slideshow-wrapper .swiper-button-prev {
        display: none;
    }

    .stgb {
        width: 100%;
    }

    .plitka-item {
        width: 100%;
    }

    .brands-more {
        display: none!important;
    }

    .product-price-block {
        display: none;
    }

    .product-price-block + .buy-list-variants {
        margin-top: 0;
    }

    .buy-side {
        margin-top: 30px;
    }

    .img_part .img_gallery_small-carousel li {
        width: calc((100vw/* - 30px*/)/4 - 10px)!important;
    }

    .img_part_small {
        margin-left: -15px;
        margin-right: -15px;
    }

	.img_part .img_gallery_small-carousel {
        display: flex;
        flex-wrap: wrap;
        /*justify-content: center;*/
    }
	
	.img_part .img_gallery_small-carousel.jcarousel-list {
        display: flex;
		/*
        width: auto!important;
        left: auto!important;
		*/
    }

    .material-full ~ ymaps {
        height: 300px !important;
    }

    .review-verdict {
        margin-bottom: 0;
    }

    .usergrade {
        width: auto;
    }

    .my-review label {
        width: 110px;
    }

    .myrate .rating {
        float: none;
        width: 100%;
    }

    .my-review .form-control {
        width: 100%;
    }

    .my-review-send {
        width: 100%;
        float: none;
    }

    .my-review-send .btn {
        width: auto;
    }

    #viewedproducts .owl-tiles .owl-controls .owl-nav .owl-prev {
        left: 5px;
        right: auto;
    }

    #viewedproducts .owl-tiles .owl-controls .owl-nav .owl-next {
        left: 45px;
        right: auto;
    }

    .viewedproducts-header {
        margin-bottom: 45px;
    }

    .product-col-left {
        float: none;
        width: auto;
    }

    .product-col-right {
        margin-left: 0;
        float: none;
        clear: both;
        margin-top: 25px;
        width: 100%;
    }

    .product-col-center {
        margin-top: 30px;
    }

    .product-col-main {
        margin-top: 40px;
    }


    .price-with-amount {
        display: none;
    }

    .favorite-like-comment-compare {
        /*align-items: center;*/
        justify-content: space-between;
        width: 100%;
    }

    .favorite-like-comment-compare .little-icons i {
        font-size: 24px;
    }
	
	.favorite-like-comment-compare .little-icons svg {
		width: 24px;
		height: 24px;
	}

    .write-review-top {
        font-size: 24px;
    }

    .buttons-on-product-card--buy {
        display: none;
    }

    .buttons-on-product-card--buy1click {
        display: none;
    }


    .fbuy {
        display: block;
        display: flex;
    }

    .fbuy-replace {
        display: block;
    }



    .plitka-item #favorite i.grey-fa-icon {
        font-size: 30px;
    }
	
	.favorite-like-comment-compare .little-icons a {
		line-height: 30px;
		width: 30px;
	}

    .plitka-item .favorite-like-comment-compare .little-icons i {
        font-size: 30px;
    }
	
	.plitka-item .favorite-like-comment-compare .little-icons svg {
		width: 30px;
		height: 30px;
	}

    .plitka-item .price-with-amount-and-little-buttons {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .plitka-item .price-with-amount {
        display: block;
    }

    .plitka-item .kolvo {
        display: none;
    }

    .plitka-item .price-with-amount .input-group input {
        width: 60px;
        height: 30px;
        font-size: 16px;
    }

    .plitka-item .price-with-amount .input-group .btn-sm {
        font-size: 20px;
    }

    .plitka-item .favorite-like-comment-compare {
        justify-content: flex-end;
    }

    .plitka-item .tiles-buy-buttons .plitka-buy,
    .tiles-buy-buttons .plitka-buy-fast {
        padding-top: 9px;
        padding-bottom: 9px;
        min-height: 42px;
        font-size: 20px;
        line-height: 1;
    }

    .plitka-item .tiles-buy-buttons .plitka-buy i,
    .plitka-item .tiles-buy-buttons .plitka-buy-fast i {
        position: relative;
        top: -1px;
        font-size: 20px;
        vertical-align: middle;
    }






    .doplistitem-center {
        float: none;
        width: calc(100% - 95px);
    }



    .nav-tabs-wrapper2 {
        margin-left: -15px;
        margin-right: -15px;
    }

    .nav-tabs-wrapper {
    }

    .nav-tabs {
        display: inline-flex;
        justify-content: space-between;
        margin: 0;
        padding: 0 15px;
    }

    .nav-tabs:before {
        left: 15px;
        right: 15px;
    }

    .nav-tabs li {
        float: none;
        white-space: nowrap;
    }

    .nav-tabs a {
        text-align: center;
    }


    .products-content .plitka-name {
        height: auto;
    }


    .review-add-images {
        float: left;
        margin-top: 8px;
    }

    .carttable-product {
        margin-bottom: 10px;
        margin-right: 30px;
    }

    .carttable-product:after {
        display: table;
        clear: both;
        content: "";
    }

    .carttable tr {
        position: relative;
        display: block;
        clear: both;
        padding: 10px 0;
    }

    .carttable tr:after {
        display: table;
        clear: both;
        content: "";
    }

    .carttable tr td {
        border-top: none !important;
        display: block;
        padding: 0 !important;
    }



    .carttable tr {
        border-bottom: 1px solid #ccc;
    }

    .carttable .carttable-count .input-group {
        width: 80px;
        margin: 0 auto;
    }

    .carttable-product-name {
        min-height: 0;
    }

    .carttable thead {
        display: none;
    }

    .carttable-priceone,
    .carttable-priceall,
    .carttable .carttable-count {
        float: left;
        width: 33.33%;
        text-align: left;
    }

    .carttable .carttable-count {
        text-align: center;
        padding: 0 !important;
    }

    .carttable-priceall {
        text-align: right;
    }

    .carttable-remove {
        position: absolute;
        top: 16px;
        right: 0;
    }

    .ordertable-product {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .ordertable-product:after {
        clear: both;
        display: table;
        content: "";
    }

    .ordertable-product .ordertable-label {
        display: none;
    }

    .ordertable-product-name {
        min-height: 0;
    }

    .ordertable-product-name:after {
        display: table;
        clear: both;
        content: "";
    }

    .ordertable thead {
        display: none;
    }

    .ordertable tr, .complecttable tr {
        display: block;
        clear: both;
        padding: 10px 0;
    }

    .ordertable tr:after, .complecttable tr:after {
        clear: both;
        display: table;
        content: "";
    }

    .ordertable tr, .complecttable tr {
        border-top: 1px solid #f5f5f5 !important;
    }


    .ordertable tr td, .complecttable tr td {
        border-top: none !important;
        display: block;
        padding: 0 !important;
    }

    .ordertable-priceone,
    .carttable-priceall,
    .ordertable .ordertable-count,
    .complecttable .ordertable-count {
        float: left;
        width: 33.33%;
        text-align: left;
    }

    .ordertable-product {
        width: auto;
    }

    .carttable-priceall {
        text-align: right;
    }

    .ordertable .ordertable-count,
    .complecttable .ordertable-count {
        text-align: center;
        padding: 0 !important;
    }

    .ordertable-label {
        display: block;
        font-size: 11px;
        color: #999;
        margin: 0 0 2px;
    }


    .userdata .form-group.userdata-nomargin {
        margin-bottom: 15px;
    }

    .cart-col2 {
        width: 100%;
    }

    .userdata .hint {
        padding-left: 6px;
    }

    h1 {
        font-size: 22px;
    }


    .payment-method {
        width: 100%;
    }

    .pagination > li > a, .pagination > li > span {
        font-size: 14px;
        padding: 4px 6px;
        min-width: 30px;
        text-align: center;
    }

    .show-more {
        font-size: 16px;
        line-height: 1.9;
        padding: 4px 9px;
        background-color: #f8f5ee;
        border-color: #ccc;
        margin-bottom: 25px;
    }


    #sort.sort-plain {
        position: relative;
    }

    #sort.sort-plain li {
        display: none;
    }

    #sort.sort-plain li.current {
        display: block;
    }

    #sort.sort-plain li a {
        position: relative;
        font-size: 16px;
        padding: 3px 5px;
        border-color: #ccc;
        background-color: #fff;
        padding-right: 25px;
    }

    #sort.sort-plain li a:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 19px;
        background-color: #f8f5ee;
        border-left: 1px solid #ccc;
        content: '';
    }

    #sort.sort-plain li a:after {
        position: absolute;
        top: 11px;
        right: 4px;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #72706d transparent transparent transparent;
        content: '';
    }

    #sort.sort-plain li a i {
        font-size: 14px;
        margin-right: 5px;
    }

    .sort-modal-trigger {
        display: block!important;
        position: absolute!important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .sort-modal-trigger a {
        display: block!important;
        position: absolute!important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


    #sort {


    }

}

@media (max-width: 459px) {

    .radioname {
        font-size: 13px;
    }
}

@media (max-width: 439px) {

    /*.rate-sort {
        width: auto;
    }*/

    .rate-sort-item i {
        display: none;
        padding: 9px 5px 10px;
    }

    .rate-sort-item {
        padding: 5px 5px;
    }

    .review-shop-all {
        margin-top: 0;
    }

    .product-links {
        position: relative;
    }

    .product-links .buy-compare {
        margin-top: 30px;
    }
	
	.selected-payment-image {
		display: none;
	}
	
	.selected-payment-text {
		margin-left: 0px;
	}
}

@media (max-width: 419px) {

    header {
        margin: 6px 0;
    }

    .container {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .search-box {
        padding-left: 12px;
        padding-right: 12px;
		padding-bottom: 0;
		top: 7px;
    }

    .logo_tablet {
        width: 128px;
        height: 36px;
        background-position: left center;
        margin-top: 1px;
    }

    .menu-switcher {
        margin-top: 4px;
        margin-right: 15px;
        height: 24px;
        width: 30px;
    }

    .menu-switcher:before {
        height: 4px;
        width: 30px;
        top: 0;
    }

    .menu-switcher > span:first-child {
        height: 4px;
        width: 30px;
        margin-top: -2px;
    }

    .menu-switcher:after {
        height: 4px;
        width: 30px;
    }

    #cart {
        width: 36px;
        height: 36px;
        margin-top: 2px;
        padding: 0;
    }

    #search {
        line-height: 36px;
        width: 36px;
        margin-top: 2px;
        height: 36px;
        display: none;
    }

    #search:before {
        width: 18px;
        height: 18px;
    }

    #make-callback {
        margin-left: auto;
        margin-right: auto;
    }

    .mmenu-icon {
        width: 36px;
        margin-top: 2px;
        height: 36px;
    }

    .cart-mini-image {
        background-size: contain;
        width: 24px;
        height: 21px;
        margin: -10px 0 0 -11px;
    }


    .popular-categories a {
        width: 50%; /*16.6%;*/
        /*191px;*/
    }

    .popular-categories2 a {
        width: 191px;
    }

    .b-top {
        width: 32px;
        height: 32px;
        padding: 3px 0;
        right: 12px;
        bottom: 12px;
        font-size: 18px;
    }

    .radioname .product-variant-id {
        float: none;
        padding-left: 0;
    }

    h1 {
        font-size: 20px;
    }


    .img_part_small {
        margin-left: -12px;
        margin-right: -12px;
    }

    .img_part .img_gallery_small-carousel li {
        width: calc((100vw/* - 24px*/)/4 - 10px)!important;
    }


}

@media (max-width: 413px) {

    .popular-categories a {
        width: 50%; /*16.6%;*/
        /*171px;*/
    }

    .popular-categories2 a {
        width: 171px;
    }

    #sort {
        padding-top: 0;
    }

    #sort li.disabled {
        float: none;
    }

    .my-review {
        display: block;
        width: auto;
    }

    .my-review .form-group {
        display: block;
        width: auto;
        margin-bottom: 15px;
    }

    .my-review .form-group:after {
        display: table;
        clear: both;
        content: "";
    }
}

@media (max-width: 375px) {

    .popular-categories a {
        width: 50%; /*16.6%;*/
        /*164px;*/
    }

    .popular-categories2 a {
        width: 164px;
    }

}


@media (max-width: 359px) {

    .popular-categories a {
        width: 50%; /*16.6%;*/
        /*144px;*/
    }

    .popular-categories2 a {
        width: 144px;
    }
	
	.whatsapp-icon {
		display: none;
	}
}


@media (min-width: 1000px) {
    .vygodaprod .discount_info, .vygodaprod .discount_info:hover {
        background-position-x: calc(98%); /*calc(50% - 75px);*/
    }
}

@media (min-width: 1100px) {
    /*.container {
        width: 1080px !important;
    }

    .company-inner {
        width: 1080px !important;
    }*/

    .vygodaprod .discount_info, .vygodaprod .discount_info:hover {
        background-position-x: calc(98%); /*calc(50% - 75px);*/
    }
}

@media (min-width: 1200px) {
    /*.wrapall */.container {
        width: 1100px !important;
    }

    .company-inner {
        width: 1100px !important;
    }
}

@media (min-width: 1300px) {
    /*.wrapall */.container {
        width: 1200px !important;
    }

    .company-inner {
        width: 1200px !important;
    }

    .vygodaprod .discount_info, .vygodaprod .discount_info:hover {
        background-position-x: calc(50% - 100px);
    }
}

@media (min-width: 1400px) {
    /*.wrapall */.container {
        width: 1300px !important;
    }

    .company-inner {
        width: 1300px !important;
    }
}

@media (max-width: 360px) {
	.whatsapp-icon {
        margin-left: 18px;
    }
	
	.phone-icon {
        margin-left: 18px;
    }
	
	#cart {
        margin-left: 18px;
    }
	
	.mmenu-icon {
        margin-left: 18px;
    }
}

.modal-backdrop {
  z-index: -1;
  background-color: #fff;
}

@media only screen and (max-width: 480px) {
	#sortview .sort-wrapper.sticky {
		position: fixed;
		top: 0;
		left: 0;
		margin-top: 73px;
		background-color: #fff;
		z-index: 100;
		width: 97%;
		margin-left: 5px;
		margin-right: 15px;
	}
}

#contact-center .modal-content {
	min-width: 333px;
}